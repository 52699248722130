<script setup>
import { computed, inject, onMounted, ref } from 'vue'
import { ApiService } from '@/js/services/api'

const model = defineModel()
const dataset = inject('dataset')

const filterData = ref(null)

const filterDisplay = computed(() => {
  let entries = []

  Object.keys(model.value).forEach((key) => {
    if (Array.isArray(model.value[key]) && model.value[key].length)
      entries = entries.concat(model.value[key].map((e) => ({ ...e, key })))
  })

  return entries
})

const removeFilter = (key, id) => {
  model.value[key] = model.value[key].filter((e) => e._id != id)
}

const clearFilters = () => {
  Object.keys(model.value).forEach((e) => {
    model.value[e] = []
  })
}

onMounted(async () => {
  if (dataset.apiFilters)
    filterData.value = await ApiService.get(dataset.apiFilters)
})
</script>

<template>
  <div>
    <div class="columns is-multiline is-vcentered is-variable is-2 mb-5">
      <div class="column is-narrow">
        <div class="field">
          <p class="control has-icons-right">
            <input
              v-model="model.search"
              class="input"
              type="text"
              placeholder="Caută"
            />

            <b-icon icon="magnifying-glass" class="is-right" size="is-size-4" />
          </p>
        </div>
      </div>

      <template v-if="filterData">
        <div class="column is-narrow ml-auto is-hidden-mobile">
          <p class="has-text-grey">Filtrează după</p>
        </div>

        <div
          class="column is-narrow"
          v-for="filter in filterData"
          :key="'filter-' + filter.key"
        >
          <dropdown-select
            v-model="model[filter.key]"
            v-bind="{
              options: filter.values,
              field: 'nume',
              placeholder: filter.label,
              multiple: true,
            }"
            class="is-expanded"
          />
        </div>
      </template>

      <div class="column is-12" v-if="filterDisplay?.length">
        <div class="tags tags-filters">
          <a
            v-for="(item, index) in filterDisplay"
            :key="`tags-filter-${item.key}-${index}`"
            @click.prevent="removeFilter(item.key, item._id)"
            class="tag"
          >
            <span>{{ item.nume }}</span>
            <b-icon icon="x" size="is-size-7" />
          </a>

          <a
            class="tag is-underlined has-text-primary"
            @click="clearFilters()"
            v-if="filterDisplay?.length"
          >
            Șterge toate filtrele
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
