class Api {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      this.csrf = document.getElementsByName('csrfmiddlewaretoken')[0].value
    })
  }

  request(path, opts, noBase) {
    return fetch(noBase ? path : '/api/' + path, {
      ...opts,
      headers: { 'X-CSRFToken': this.csrf, 'Content-Type': 'application/json' },
    })
      .then((response) => {
        return response.status !== 500 ? response.text() : '{}'
      })
      .then((response) => {
        return response ? JSON.parse(response) : null
      })
  }

  get(path, opts) {
    return this.request(path, { ...opts, method: 'GET' })
  }

  post(path, opts) {
    return this.request(path, {
      ...opts,
      method: 'POST',
    })
  }

  delete(path, opts) {
    return this.request(path, { ...opts, method: 'DELETE' })
  }

  //

  getTimetable() {
    return this.get('scolarizari/orar/')
  }

  getBooks(params) {
    return this.get('biblioteca/titluri/?' + new URLSearchParams(params))
  }

  postReservation(params) {
    return this.post('biblioteca/rezerva/', { body: JSON.stringify(params) })
  }

  getReservations() {
    return this.get('biblioteca/rezerva/')
  }

  deleteReservation(id) {
    return this.delete('biblioteca/rezerva/' + id + '/')
  }

  search(params) {
    return this.request(
      '/cauta/?' + new URLSearchParams(params),
      {
        method: 'POST',
      },
      true
    )
  }
}

export const ApiService = new Api()
