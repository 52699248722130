<script setup>
import { nextTick, onMounted, ref, useTemplateRef, watch } from 'vue'
import debounce from 'lodash.debounce'
import slugify from 'slugify'

const model = defineModel()

const props = defineProps({
  customClass: String,
  placeholder: String,
  field: String,
  readonly: Boolean,
  multiple: Boolean,
  options: Array,
})

const query = ref(null)
const results = ref([])
const searchable = ref(false)
const displayOptions = ref(false)
const searchInput = useTemplateRef('searchInput')

const search = debounce(() => {
  if (query.value.trim().length > 1) {
    results.value = props.options.filter((e) =>
      slugify(props.field ? e[props.field] : e, { lower: true }).includes(
        slugify(query.value, { lower: true })
      )
    )
  } else computeResults()
}, 250)

const focusInput = async (visible) => {
  if (!searchable.value) return

  query.value = ''
  computeResults()

  nextTick(() => {
    if (visible) searchInput.value.focus()
  })
}

const computeOptions = () => {
  searchable.value = props.options && props.options.length > 30
  displayOptions.value = props.options && props.options.length < 100

  computeResults()
}

const computeResults = () => {
  results.value = !searchable.value || displayOptions.value ? props.options : []
}

watch(
  () => props.options,
  () => {
    computeOptions()
  }
)

onMounted(() => {
  computeOptions()
})
</script>

<template>
  <b-dropdown
    v-model="model"
    v-bind="{
      animation: null,
      position: 'is-bottom-left',
      multiple,
    }"
    aria-role="list"
    @active-change="focusInput"
    :class="[{ 'is-searchable': searchable }, customClass]"
    :mobile-modal="false"
  >
    <template #trigger>
      <button
        type="button"
        class="button is-fullwidth"
        :class="{
          'is-primary': (!multiple && model) || (multiple && model?.length),
        }"
      >
        <template v-if="multiple || !model">
          <span v-text="placeholder" class="mr-auto" />
          <span
            class="tag"
            v-if="multiple && model?.length"
            v-text="model.length"
          />
        </template>
        <template v-else>
          <span v-text="model" class="mr-auto" />
        </template>
        <b-icon icon="caret-down"></b-icon>
      </button>
    </template>

    <b-dropdown-item
      custom
      class="dropdown-search-item"
      aria-role="listitem"
      v-if="searchable"
    >
      <div class="field">
        <p class="control has-icons-right">
          <input
            class="input"
            v-model="query"
            placeholder="Caută"
            @input="search"
            ref="searchInput"
          />

          <b-icon icon="magnifying-glass" class="is-right" size="is-size-5" />
        </p>
      </div>
    </b-dropdown-item>

    <b-dropdown-item
      v-for="(option, index) in results"
      :key="`option-${index}`"
      :value="option"
    >
      {{ field ? option[field] : option }}
    </b-dropdown-item>
  </b-dropdown>
</template>
